import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as ReactBootStrap from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import {
  getAllPublishers,
  updatePublisher,
  clearPublisherImpressionsAlert,
} from "../../services/api-service/publisher-service";
import style from "./AdminPublisherTable.module.scss";

function AdminPublisherTable({ updatePublishers }) {
  const [rows, setRows] = useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState();
  const adTypeLink = "LINK";
  const adTypeHover = "HOVERBOX";

  async function getPublishers() {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    getAllPublishers(token).then(
      (res) => {
        setIsLoaded(true);
        // Filter publishers that have 'NL' in their languages array
        const filteredPublishers = res.publishers.filter(publisher => publisher.languages.includes('NL'));
        setRows(filteredPublishers);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }

  useEffect(() => {
    getPublishers();
  }, [updatePublishers]);

  async function patchPublisher(
    publisherId,
    publisherActive,
    publisherExcludedBrands,
    publisherExcludedPages,
    publisherExcludedProducts,
    publisherAdType,
    publisherAllowAlts,
    publisherAllowInspo,
    publisherAllowGL,
    publisherAllowUtensil,
    publisherIsVegan,
  ) {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    updatePublisher(
      token,
      publisherId,
      publisherActive,
      publisherExcludedBrands,
      publisherExcludedPages,
      publisherExcludedProducts,
      publisherAdType,
      publisherAllowAlts,
      publisherAllowInspo,
      publisherAllowGL,
      publisherAllowUtensil,
      publisherIsVegan
    );
    getPublishers();
  }

  async function clearAlert(publisherId) {
    const token = await getAccessTokenSilently({ ignoreCache: true });
    clearPublisherImpressionsAlert(token, publisherId);
    getPublishers();
  }

  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  } else if (!isLoaded) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  } else {
    return (
      <ReactBootStrap.Table striped size="sm" className={style.tableStyle}>
        <thead>
          <tr className={style.tableHeader}>
            <th>ID</th>
            <th>Publisher</th>
            <th>Language</th>
            <th>Balance</th>
            <th>Excluded Brands</th>
            <th>Excluded Products</th>
            <th>Vegan</th>
            <th>Publisher State</th>
            <th>Hoverbox</th>
            <th>Alternatives</th>
            <th>Inspo Tips</th>
            <th>GL</th>
            <th>Utensil</th>
          </tr>
        </thead>
        <tbody className={style.tableBody}>
          {rows?.map((item) => (
            <tr
              className={item.impressions_drop ? style.alertStyle : null}
              key={item.id}
            >
              <td>{item.id}</td>

              <td>
                <div>{item.name} </div>
                <div>
                  {item.impressions_drop && (
                    <button
                      className="start-stop-ad"
                      style={{ marginTop: "5px" }}
                      onClick={(e) => {
                        clearAlert(item.publisher_id);
                      }}
                    >
                      Clear alert
                    </button>
                  )}
                </div>
              </td>
              <td>
                {item.languages.map((item) => (
                  <p className={style.languageStyle} key={item[0]}>
                    {item}
                  </p>
                ))}
              </td>
              <td>{item.balance}</td>
              <td>
                {item.excluded_brands.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      className={style.dropDownStyle}
                      id="dropdown-basic"
                    >
                      Excluded Brands
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {item.excluded_brands.map((brand) => (
                        <Dropdown.Item key={brand}>{brand}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : item.excluded_brands.length === 1 ? (
                  <p>{item.excluded_brands[0]}</p>
                ) : (
                  <p className={style.emptyExcluded}>-</p>
                )}
              </td>
              <td>
                {item.excluded_products.length > 1 ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      className={style.dropDownStyle}
                      id="dropdown-basic"
                    >
                      Excluded Products
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {item.excluded_products.map((product) => (
                        <Dropdown.Item key={product}>{product}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : item.excluded_products.length === 1 ? (
                  <p>{item.excluded_products[0]}</p>
                ) : (
                  <p className={style.emptyExcluded}>-</p>
                )}
              </td>
              <td>{item.is_vegan ? 'Vegan' : 'Non-vegan'}</td>
              <td>
                <button
                  className={item.active ? style.stopButton : style.startButton}
                  onClick={(e) =>
                    patchPublisher(
                      item.id,
                      !item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      item.ad_type,
                      item.allow_alts,
                      item.allow_inspo,
                      item.allow_gl,
                      item.allow_utensil,
                      item.is_vegan
                    )
                  }
                >
                  {item.active ? "Deactivate" : "Activate"}
                </button>
              </td>
              <td>
                <button
                  className={
                    item.ad_type === adTypeHover
                      ? style.stopButton
                      : style.startButton
                  }
                  onClick={(e) => {
                    const newAdType =
                      item.ad_type === adTypeLink ? adTypeHover : adTypeLink;
                    patchPublisher(
                      item.id,
                      item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      newAdType,
                      item.allow_alts,
                      item.allow_inspo,
                      item.allow_gl,
                      item.allow_utensil,
                      item.is_vegan
                    );
                  }}
                >
                  {item.ad_type === adTypeHover ? "Turn Off" : "Turn On"}
                </button>
              </td>
              <td>
                <button
                  className={
                    item.allow_alts ? style.stopButton : style.startButton
                  }
                  onClick={(e) =>
                    patchPublisher(
                      item.id,
                      item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      item.ad_type,
                      !item.allow_alts,
                      item.allow_inspo,
                      item.allow_gl,
                      item.allow_utensil,
                      item.is_vegan
                    )
                  }
                >
                  {item.allow_alts ? "Turn Off" : "Turn On"}
                </button>
              </td>
              <td>
                <button
                  className={
                    item.allow_inspo ? style.stopButton : style.startButton
                  }
                  onClick={(e) =>
                    patchPublisher(
                      item.id,
                      item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      item.ad_type,
                      item.allow_alts,
                      !item.allow_inspo,
                      item.allow_gl,
                      item.allow_utensil,
                      item.is_vegan
                    )
                  }
                >
                  {item.allow_inspo ? "Turn Off" : "Turn On"}
                </button>
              </td>
              <td>
                <button
                  className={
                    item.allow_gl ? style.stopButton : style.startButton
                  }
                  onClick={(e) =>
                    patchPublisher(
                      item.id,
                      item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      item.ad_type,
                      item.allow_alts,
                      item.allow_inspo,
                      !item.allow_gl,
                      item.allow_utensil,
                      item.is_vegan
                    )
                  }
                >
                  {item.allow_gl ? "Turn Off" : "Turn On"}
                </button>
              </td>
              <td>
                <button
                  className={
                    item.allow_utensil ? style.stopButton : style.startButton
                  }
                  onClick={(e) =>
                    patchPublisher(
                      item.id,
                      item.active,
                      item.excluded_brands,
                      item.excluded_pages,
                      item.excluded_products,
                      item.ad_type,
                      item.allow_alts,
                      item.allow_inspo,
                      item.allow_gl,
                      !item.allow_utensil,
                      item.is_vegan
                    )
                  }
                >
                  {item.allow_utensil ? "Turn Off" : "Turn On"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </ReactBootStrap.Table>
    );
  }
}

export default AdminPublisherTable;
